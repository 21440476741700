.btn-sign-out-default {
  color: #333;
  background-color: transparent;
  border: solid 1px #eee;
}

.btn-sign-out {
  display: inline-flex;
  padding: 5px 12px;
  margin-bottom: 0;
  font-size: 16px;
  font-weight: normal;
  line-height: 1.42857;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  touch-action: manipulation;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-image: none;
  border: 1px solid rgba(0, 66, 95, 0.4);
  border-radius: 2px;

  &:hover {
    background-color: #eee;
  }
}
